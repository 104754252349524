<template>
  <section
    data-key-anchor="Инфраструктура"
    class="infrastructur-block"
    :class="$customSectionClasses(props.cfgs)"
  >
    <div class="container">
      <div
        v-if="values?.title"
        class="h2"
      >
        {{ values?.title }}
      </div>
      <div
        v-if="objectList.length"
        class="infrastructure__content"
      >
        <div
          :class="{'__active': isOpenPointList}"
          class="controls scroll-customize"
        >
          <InfrastructuraControls
            :sections-list="objectList"
            @onChangeActiveCategory="onChangeActiveCategory"
          />
        </div>
        <div class="map">
          <div class="closeBtnMenu__root">
            <div
              @click="openMenu"
            >
              <div
                v-if="isOpenPointList"
                class="closeBtnMenu__container"
              >
                <img
                  src="~/assets/img/svg/common/close-black.svg"
                  alt="close"
                >
                <span>Закрыть меню</span>
              </div>

              <div
                v-else
                class="closeBtnMenu__container"
              >
                <span>Меню</span>
              </div>
            </div>
          </div>
          <InfrastructureMap
            v-if="objectList.length"
            :points-list="objectList"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import type { SectionTypes } from '~/plugins/customSectionClasses';
import type { IBlockDefaultProps } from '#sitis/internal/models/common';
import type { InfrastructureBlockItemType } from '~/types/InfrastructureBlockTypes';
import InfrastructureMap from '~/cms/blocks/infrastructura-block/InfrastructureMap.vue';

const { $customSectionClasses } = useNuxtApp();

type InfrastructureBlockType = Omit<IBlockDefaultProps, 'values' | 'cfgs' | 'extras'> & {
	values: {
		title: string;
		items: InfrastructureBlockItemType[];
	};
	extras: {
		icons: Record<number, string>
	}
	cfgs: SectionTypes;
}
const props = defineProps<InfrastructureBlockType>();

const objectList = ref<InfrastructureBlockItemType[]>([]);
const isOpenPointList = ref<boolean>(false);

const initData = () => {
  const iconList = { ...props.extras?.icons || {} };
  const pointsList = [...props.values?.items || []];

  objectList.value = pointsList.map((point) => {
    let icon = '';

    Object.entries(iconList).forEach(([key, values]) => {
      if (+key === point.icon_id) {
        icon = values;
      }
    });

    return { ...point, isHidden: false, icon };
  });
};

const onChangeActiveCategory = ({ index }: { index: number }) => {
  const newPointsList = [...objectList.value];
  newPointsList[index].isHidden = !newPointsList[index].isHidden;
  objectList.value = newPointsList;
};
const openMenu = () => {
  isOpenPointList.value = !isOpenPointList.value;
};
onMounted(() => {
  initData();
});
</script>

<style scoped lang="scss">
@import "@/assets/scss/media";

.infrastructur-block {
	& .infrastructure__content {
		display: flex;
		height: 720px;
		background: var(--white);
		border: 1px solid var(--primary-20);
		border-radius: 12px;
		overflow: hidden;
	}

	& .controls {
		background: #F5F4F8;
		padding: 40px 30px 40px 40px;
		box-sizing: border-box;
		width: 30%;
		max-width: 280px;
		overflow-y: scroll;
	}

	.map {
		display: flex;
		flex-direction: column;
		flex: 1;
		width: 70%;
	}
}

.closeBtnMenu__root {
	display: none;
}

@include media('md') {
	.infrastructur-block {
		.controls {
			padding: 20px;
		}
	}
}

@include media('md') {
	.infrastructur-block {
		.infrastructure__content {
			position: relative;
			height: auto;

			.controls {
				padding: 16px 20px;
				width: 60%;
				max-width: initial;
				position: absolute;
				z-index: 2;
				top: 40px;
				bottom: 0;
				border-radius: 0 0 0 20px;
				transform: translateX(-100%);
				transition: all 0.5s ease-in-out;

				&.__active {
					transform: translateX(0);
					transition: all 0.5s ease-in-out;
				}
			}

			.map {
				width: 100%;
				border-radius: 0 0 20px 20px;
			}
		}
	}

	.closeBtnMenu__root {
		position: relative;
		z-index: 1;
		margin: 0 auto;
		display: flex;
		justify-content: center;
		width: 100%;
		box-shadow: 0 2px 10px rgb(0 0 0 / 10%);

		& .closeBtnMenu__container {
			display: flex;
			align-items: center;
			padding: 10px 0;
			font-weight: 600;
			font-size: 14px;
			line-height: 20px;
			color: var(--primary);
			gap: 4px;

			& > img {
				filter: invert(15%) sepia(29%) saturate(4340%) hue-rotate(247deg) brightness(88%) contrast(102%);
				width: 12px;
				height: 12px;
			}
		}
	}

}
</style>
